<template>
  <div class="main-container"> 
    <img :src="require('../assets/bg.png')" class="bg">

    <div class="wrapper">
      <div id="form">
        <div class="logo">
          <img :src="require('../assets/logo.png')" >
        </div>
        
        <h4 class="text-center text-white">{{ $t('setupWallet.selectWallet') }}</h4>

        <div class="alert alert-danger" v-if="errors.profile.show">
          {{ errors.profile.msg }}
        </div>
        <div v-if="isNetworkOk">
            <div class="connect-metamask-wrapper">
                <div @click="connectWallet"> CONNECT <img src="../assets/metamask.svg" > </div>
            </div> 
            <h4 class="text-center">Or</h4>

            <div >
              <button class="btn btn-main" @click="gotoDashboard">Generate Wallet Address</button>
            </div>
       
        </div>

    

      </div>
    </div>
    
  </div>
</template>

<script>
 
  import { ethers, BigNumber } from 'ethers'
  export default {
    name: 'SetupWallet',
    components: {},
    data: function() {
      return  {
        targetNetworkId: '',
        isNetworkOk: true,
        errors: {
          profile: {
            show:false,
            msg: ""
          }
        }
      }
    },
    methods: {
      gotoDashboard() {
        window.location.href = "/";
      },
      async checkNetwork() {
        if (window.ethereum) {
          const currentChainId = await window.ethereum.request({
            method: 'eth_chainId',
          })
          console.log('Current network  :>> ', currentChainId)

            if(this.targetNetworkId != currentChainId) {
              this.errors.profile.show = true;
              this.errors.profile.msg = this.i18n.t('setupWallet.pleaseConnectNetwork');
              this.isNetworkOk = false;
            } else {
              this.isNetworkOk = true;
            }
        }
      },

      updateMetamask: function(address) {
 

        const options = {
            method: 'GET',
            headers: {
              'content-type': 'application/x-www-form-urlencoded',
              'blox-authz': localStorage.getItem("tokenId"),
            }, 
            url: '/cx/profile/updatemetamask?address=' + address,
        };

        this.axios(options)
          .then((response) => {
             this.gotoDashboard();  
              
          }).catch((err) => {
            console.log(err.response.data.msgText) 
            this.errors.profile.show = true;
              this.errors.profile.msg = err.response.data.msgText;
          })
      },


      async connectWallet(){
        try {
         
          const data = await window.ethereum.request({
            method: 'eth_requestAccounts',
          })
          console.log('data :>> ', data)

          this.updateMetamask(data[0])
        
          console.log('DApp connected to your wallet 💰')


        } catch (error) {

          console.error('Error connecting DApp to your wallet')
          console.error(error)
          this.$bvModal.show('metamask-alert');
        }
      },
    },

    beforeMount(){

      this.targetNetworkId = this.init.isTest ? "0x453" : "0x454";
      this.checkNetwork(); 

      const provider = new ethers.providers.Web3Provider(window.ethereum, "any")
      provider.on("network", (newNetwork, oldNetwork) => {
          // When a Provider makes its initial connection, it emits a "network"
          // event with a null oldNetwork along with the newNetwork. So, if the
          // oldNetwork exists, it represents a changing network
          if (oldNetwork) {
              window.location.reload();
          }
      });
      
    }
  }
</script>

<style scoped lang="scss">

 .connect-metamask-wrapper {
        background: #FFFFFF;
        padding: 0.5em 1em;
        border-radius: 6px;
        cursor: pointer;
        color: #c08000;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            margin-left: 0.5em;
        }
    }

  .main-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding:1em;
  }
  .logo {
    display:flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1em;
    img{
      width: 60px;  
    }
    
  }

  .wrapper { 
     background: linear-gradient(to bottom, #231f20, #070607);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 2em;
     color: #FFF;
  }


  /*med*/
  @media(min-width: 40em) {
    
  }
  /*large*/
  @media(min-width: 64em) {

   
  }
  /*xl*/
  @media(min-width: 87.5em) {
    
  }


  /*sm*/
  @media(max-width: 39.9375em) {
    .wrapper {
      padding: 1em !important;
      h4 {
        font-size: 1.25em;
      }

      .email-or-mobile { 

        .btn {
          font-size: 1em;
        }
      }
    }

    button, input {
      font-size: 85%;
    }
  }
  /*med*/
  @media(max-width: 63.9375em) {

     #app {
      
      padding: 2em;
      font-size: 85%;

    }
  }

  /*large*/
  @media(max-width: 87.4375em) {
    
  }

  @media(max-width: 768px) {
 
  }
</style>



